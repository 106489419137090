import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import marked from "marked"
import { graphql } from "gatsby"

import "../scss/modules/engagement-page.scss"
import FloatingBtn from "../components/floating-btn"

const IndexPage = data => (
  <Layout>
    <div className={"engagement-page"}>
      <Seo
        title={data.data.allStrapiEngagement.nodes[0].SEO_titre}
        description={data.data.allStrapiEngagement.nodes[0].SEO_description}
      />
      <div>
        <h1 className={"title"}>NOS ENGAGEMENTS</h1>
      </div>
      <div className={"wrapper-items"}>
        {data.data.allStrapiEngagement.nodes[0].engagement.map((x, index) => (
          <div className={"item"} key={index}>
            <h3 className={"subtitle"}>{x.titre}</h3>
            <div
              dangerouslySetInnerHTML={{ __html: marked(x.description) }}
            ></div>
          </div>
        ))}
        <div className={"circles"}>
          <span className={"circlesOne"}></span>
          <span className={"circlesTwo"}></span>
          <span className={"circlesThird"}></span>
          <span className={"circlesFourth"}></span>
          <span className={"circlesFifth"}></span>
        </div>
      </div>
      <FloatingBtn/>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query QueryEngagement {
    allStrapiEngagement {
      nodes {
        SEO_titre
        SEO_description
        engagement {
          titre
          description
        }
      }
    }
  }
`
